@import "variable";
@import "~bootstrap/scss/bootstrap";
@import "~fastbootstrap/dist/css/fastbootstrap.min.css";
@import "~select2/dist/css/select2.min.css";

@import "mixins";
@import "components";

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none; 
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

a {
    text-decoration: none;
    color: $primary-theme;
    @include transition;

    &:hover {
        color: $secondary-theme !important;
        text-decoration: none;
        @include transition;
    }
}

ul {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
    }
}

body {
    font-family: $body-font-theme !important;
    font-size: 16px;
    color: $text-dark-theme;
    @include transition;
}

h1,
h2,
h3 {
    font-family: $body-font-theme !important;
    font-weight: 600;
}

h4,
h5,
h6 {
    font-family: $body-font-theme !important;
    font-weight: 500;
}

p {
    color: rgba($color: $text-dark-theme, $alpha: 0.7);
}

label {
    margin-bottom: 5px;
}


.split-screen {
    display: flex;

    .left {
        width: 30%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url(../img/login-bg.png);
        background-position: center;
        background-size: cover;
        padding: 0 100px;
        position: relative;

        .icon {
            opacity: 0.5;

            img {
                height: 150px;
            }
        }
    }

    .right {
        width: 70%;
        height: 100vh;
        display: flex;
        align-items: center;
        padding: 0 100px;
        position: relative;

        .form-container {
            max-width: 400px;
            margin: auto;
        }

        .logo {
            position: absolute;
            top: 50px;
            left: 50px;

            img {
                height: 30px;
            }
        }

        .copyright {
            position: absolute;
            bottom: 50px;
            left: 50px;
        }
    }
}

header {
    padding: 12px 0;
    background-color: $secondary-theme;
    .nav-open-btn{
        padding-left: 0 !important;
        display: none;
    }
    .logo {
        img {
            height: 30px;
        }
    }

    nav { 
        ul {
            display: flex;
            gap: 10px;

            li {
                a {
                    padding: 8px 20px;
                    display: inline-flex;
                    gap: 5px;
                    align-items: center;
                    border-radius: 8px;
                    color: $white;

                    &.active {
                        background-color: rgba($color: $text-dark-theme, $alpha: 0.3);
                    }

                    &:hover {
                        background-color: $primary-theme !important;
                        // background-color: rgba($color: $text-dark-theme, $alpha: 0.3);
                        color: $white !important;
                    }
                }
            }
        }
    }
}

.content {
    .card-head {
        padding: 15px 0;
        border-bottom: thin solid rgba($color: $text-dark-theme, $alpha: 0.1);

        h5 {
            span {
                outline: none;
            }

            svg {
                height: 20px;
                stroke-width: 2px;
            }
        }

        .breadcrumb {
            .breadcrumb-item {
                padding-left: 20px;
                color: rgba($color: $text-dark-theme, $alpha: 0.6);
                display: flex;
                align-items: center;
                a{
                    display: flex;
                    align-items: center;  
                    gap: 5px;
                    &:hover{
                        svg{
                            path{
                                fill: $secondary-theme;
                            }
                        }
                    }
                }
                &.active {
                    color: rgba($color: $primary-theme, $alpha: 1);
                    ~ .breadcrumb-item{
                        a{
                            color: rgba($color: $text-dark-theme, $alpha: 0.6);
                            
                        }
                    }
                    ~ .breadcrumb-item.active{
                        a{
                            color: rgba($color: $primary-theme, $alpha: 1);
                            
                        }
                        svg{
                            path{
                                fill: $primary-theme;
                            }
                        }
                    }
                    svg{
                        path{
                            fill: $primary-theme;
                        }
                    }
                }

                &::before {
                    padding-right: 15px;
                } 
            }
        }
    }

    .card-body {
        .search {
            input {
                min-width: 300px;
                padding-left: 35px !important;
                background-image: url(../img/search.png);
                background-size: 14px;
                background-position: 10px center;
                background-repeat: no-repeat;
            }
        }

        .contacts {
            .contact-list-title {
                padding: 15px;
                display: flex;
                width: 100%;
                justify-content: space-between;
                background-color: rgba($color: $text-dark-theme, $alpha: 0.1);
                font-size: 12px;
                text-transform: uppercase;
                color: rgba($color: $text-dark-theme, $alpha: 0.7);
                font-weight: 600;

                div {
                    text-align: left;
                    font-size: 14px;
                    font-weight: 500;

                    &:first-child {
                        width: 30%;
                    }

                    &:nth-child(2) {
                        width: 60%;
                    }

                    &:last-child {
                        width: 10%;
                        min-width: 150px;
                    }
                }
            }

            .contact-list {
                li {
                    padding: 12px 15px;
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    border-bottom: thin solid rgba($color: $text-dark-theme, $alpha: 0.1);

                    &:last-child {
                        border-bottom: none;
                    }

                    div {
                        text-align: left;
                        font-size: 14px;
                        font-weight: 500;
                        color: $text-dark-theme;

                        &:first-child {
                            width: 30%;
                        }

                        &:nth-child(2) {
                            width: 60%;
                        }

                        &:last-child {
                            width: 10%;
                            min-width: 150px;
                        }
                    }

                    &:hover {
                        background-color: rgba($color: $text-dark-theme, $alpha: 0.05);
                    }
                }
            }

            .contact-head {
                h6 {
                    margin: 0;
                }

                button {
                    padding: 0;
                    border: none;
                    background: rgba($color: $text-dark-theme, $alpha: 0.1);
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 30px;
                    height: 30px;
                    border-radius: 20px;
                    @include transition;

                    svg {
                        height: 15px;
                        stroke-width: 2px;
                        @include transition;
                    }

                    &:hover {
                        background-color: red;
                        @include transition;

                        svg {
                            stroke: $white;
                            @include transition;
                        }
                    }
                }
            }
        }

        .template-listing {
            display: flex;
            // gap: 2%;
            margin-top: 20px;
            flex-wrap: wrap;

            li {
                margin-bottom: 20px; 
                position: relative;
                width: 20%; 
                padding: 0 5px;
                .item{
                    border: solid 2px rgba(37, 50, 75, 0.1);
                    border-radius: 3px;
                    box-shadow: 0 4px 12px rgba(37, 50, 75, 0.02);
                    
                    .cover{
                        position: relative;
                        .active {
                            position: absolute;
                            bottom: -15px;
                            right: 10px;
                            z-index: 999;
                            color: #5921c5;
                        }
                    }
                }
                .title {
                    padding: 15px;
                    overflow: hidden;
                    max-width: 100%;
                    text-overflow: ellipsis;
                    width: fit-content;
                    white-space: nowrap;
                    font-weight: 500;
                }

      
                .preview {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba($color: $white, $alpha: 0.8);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    opacity: 0;
                    pointer-events: none;
                    @include transition;

                    a, button {
                        display: block;
                        font-weight: 500;
                        width: 140px;
                        &.btn-preview {
                            background-color: rgb(89 32 197 / 70%);
                            color: #fff;
                            &:hover {
                                background-color: rgb(89 32 197);
                                color: #fff !important;
                            }
                        }

                        &.btn-destroy {
                            background-color: rgb(249 23 23 / 70%);
                            color: #fff;
                            &:hover {
                                background-color: rgb(249 23 23);
                                color: #fff !important;
                            }
                        }


                        &.btn-submit {
                            background-color: rgba(32, 197, 68, 0.7);
                            color: #fff;
                            &:hover {
                                background-color: rgb(32, 197, 68);
                                color: #fff !important;
                            }
                        }
                    }
                }

                &:hover {
                    border: 2px solid $primary-theme;

                    .preview {
                        opacity: 1;
                        pointer-events: all;
                        @include transition;
                    }
                }

                
            }
        }
    }
}

.send-time-type {
    + label {
        h6 {
            margin-bottom: 0;
        }

        small {
            color: rgba($color: $text-dark-theme, $alpha: 0.5);
        }

        &:hover {
            background: rgba($color: $secondary-theme, $alpha: 0.1) !important;
        }
    }

    &:checked {
        + label {
            background: rgba($color: $secondary-theme, $alpha: 0.1) !important;
            border-color: rgba($color: $secondary-theme, $alpha: 0.8) !important;

            .check {
                border: 5px solid $text-dark-theme;
            }

            small {
                color: rgba($color: $text-dark-theme, $alpha: 0.8);
            }
        }
    }
}

.form-label {
    font-size: 16px;
}

small,
.small {
    font-size: 13px;
}

.swal2-container {
    z-index: 99999 !important;
}

.swal2-popup {
    font-size: 14px !important;
    min-height: 200px !important;
    border-radius: 10px !important;
}

.swal2-container .swal2-title {
    font-size: 18px !important;
    color: #323131 !important;
}

.swal2-styled.swal2-confirm,
.swal2-styled.swal2-cancel {
    font-size: 16px !important;
    padding: 8px 20px !important;
}

.swal2-styled:focus {
    outline: 0;
    box-shadow: none !important;
}

.swal2-title {
    color: #0d0d0d !important;
}

.swal2-content {
    font-size: 16px !important;
}

.swal2-icon {
    transform: scale(0.89) !important;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    img {
        margin-right: 10px;
        -webkit-animation: spin 1s linear infinite;
        -moz-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;

        &[loading="false"] {
            display: none !important;
        }

        &[loading="true"] {
            display: inline-block !important;
        }
    }
}

.data-list {
    margin-top: 10px;

    table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 10px;
        overflow: hidden;
        margin: 10px 0;

        tr {
            th {
                font-weight: 500;
            }
            th,
            td {
                margin-bottom: 10px;
                padding: 10px 20px !important;

                &:first-child {
                    border-radius: 3px 0px 0px 3px;
                }
                &:last-child {
                    border-radius: 0px 3px 3px 0px;
                }
            }

            td {
                background-color: rgba(37, 50, 75, 0.03);
                border: thin solid rgba(37, 50, 75, 0.03);
            }
        }

        .actions {
            display: flex;
            a,
            button {
                padding: 0px !important;
                margin-right: 15px;
            }
            svg {
                margin-right: 5px;
                width: 15px;
                height: 15px;
            }
        }
    }

    .empty-list {
        display: flex;
        width: 100%;
        padding: 80px 0;
        justify-content: center;
        text-align: center;
    }
}
.lg-data-head{
    .date-wraper{
        display: none;
    }
}
.lg-data-list {



    .filter {

        .filter-icon {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            padding: 10px 0 20px 0;
            svg {
                path {
                    @include transition;
                    stroke-width: 1px;
                }
            }
        }

        button {
            width: 50px;
            height: 50px;
            background-color: rgba($color: $text-dark-theme, $alpha: 0.3);
            border-radius: 3px;
            padding: 0 !important;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                path {
                    @include transition;
                    stroke-width: 1.1px;
                }
            }

            &:hover {
                background-color: rgba($color: $primary-theme, $alpha: 0.1) !important;
                border: thin solid rgba($color: $primary-theme, $alpha: 0.1);

                svg {
                    path {
                        stroke: rgba($color: $primary-theme, $alpha: 0.8);
                        @include transition;
                    }
                }
            }

            &.active {
                background-color: rgba($color: $primary-theme, $alpha: 0.1) !important;
                border: thin solid rgba($color: $primary-theme, $alpha: 0.1);

                // svg {
                //     path {
                //         stroke: rgba($color: $primary-theme, $alpha: 0.8);
                //     }
                // }
            }
        }
    }

    .list {
        a.btn-link{
            padding: 0 !important;
            width: 25px;
            height: 25px;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            // border: thin solid rgba($color: $text-dark-theme, $alpha: 0.1);
            // background: rgba($color: $text-dark-theme, $alpha: 0.1) !important;
            @include transition;

            svg {
                height: 18px;
                @include transition;
            }

            &:hover {
                background-color: rgba($color: $primary-theme, $alpha: 0.1) !important;
                border: thin solid rgba($color: $primary-theme, $alpha: 0.1);
                @include transition;

                svg {
                    stroke: rgba($color: $primary-theme, $alpha: 0.8);
                    @include transition;
                }
            }
        }

        .list-items {
            > li {
                display: flex;
                padding: 45px 15px;
                border: thin solid rgba($color: $text-dark-theme, $alpha: 0.03);
                border-radius: 3px;
                background-color: rgba($color: $text-dark-theme, $alpha: 0.03);
                margin-bottom: 10px;

                .icon {
                    width: 5%;
                    // min-width: fit-content;
                    padding-right: 15px;
                    min-width: 70px;
                    text-align: center;
                    > svg{
                        width: 30px;
                        height: 30px;
                    }
                    .progress {
                        position: relative;
                        height: auto;
                        display: block;
                        background: transparent;

                        svg {
                            position: relative;
                            width: 50px;
                            height: 50px;
                            transform: rotate(-90deg);

                            circle {
                                width: 100%;
                                height: 100%;
                                fill: none;
                                stroke: #f0f0f0;
                                stroke-width: 4;
                                stroke-linecap: round;

                                &:last-of-type {
                                    stroke-dasharray: 122px;
                                    stroke-dashoffset: calc(122px - (122px * var(--percent)) / 100);
                                    stroke: $green;
                                }
                            }

                            text {
                                transform: rotate(90deg);
                                transform-origin: center;
                                font-size: 12px;
                                font-weight: 800;
                            }
                        }
                    }
                }

                .details {
                    width: 80%;
                    
                    p{
                        font-size: 13px;
                    }

                    .statistics {
                        display: flex;
                        align-items: center;
                        margin-top: 15px;

                        li {
                            padding: 0 15px;
                            font-weight: 500;
                            border-right: thin solid rgba($color: $primary-theme, $alpha: 0.3);
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            &:first-child {
                                padding-left: 0;
                            }

                            &:last-child {
                                border-right: 0;
                            }

                            span {
                                color: $primary-theme;
                                font-weight: 700;
                                font-size: 16px;
                            }
                        }
                    }
                }

                .status{
                    width: 20%;
                    display: flex;
                    justify-content: end;
                }

                .action {
                    width: 20%;
                    display: flex;
                    align-items: center;
                    justify-content: end;

                    a.edit {
                        padding: 0 15px;
                        height: 35px;
                        line-height: 35px;
                        color: rgba($color: $text-dark-theme, $alpha: 0.8);
                        font-weight: 600;
                        background-color: rgba($color: $text-dark-theme, $alpha: 0.1);
                        border-radius: 3px;
                        font-size: 14px;
                        svg{
                            display: none;
                        }
                        &:hover {
                            background-color: rgba($color: $text-dark-theme, $alpha: 0.2);
                            color: rgba($color: $text-dark-theme, $alpha: 0.8) !important;
                        }
                    }

                    a.more {
                        padding: 0 5px;
                        height: 35px;
                        line-height: 35px;
                        background-color: rgba($color: $text-dark-theme, $alpha: 0.1);
                        border-radius: 3px;
                        margin-left: 1px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        svg {
                            stroke: rgba($color: $text-dark-theme, $alpha: 0.8);
                        }

                        &:hover {
                            background-color: rgba($color: $text-dark-theme, $alpha: 0.2);
                        }
                    }
                }
            }
        }
    }

    &.three-column{
        .details {
            width: 60% !important;
        }
    }
}




.pagination {
    margin-top: 25px;
    .page-link {
        padding: 5px 15px;
        margin-right: 5px;
    }
}
.select2-container{
    width: 100% !important;
}

.select2-container--default .select2-selection--single {
    border-color: rgba(37, 50, 75, 0.15) !important;
}
.select2-container .select2-selection--single {
    height: 43px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 43px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 43px;
    padding: 0px 15px !important;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: #9908cb;
    color: white;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid #dfe0e4;
}

.select2-dropdown {
    border: 1px solid #dfe0e4;
}

.select2-container--default .select2-selection--multiple, .select2-container--default.select2-container--focus .select2-selection--multiple {
    height: auto !important;
}

.select2-container--default .select2-search--inline .select2-search__field {
    height: 43px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    margin: 5px 0 5px 7px !important;
}

.nav-menu{
    li{
        position: relative;
        .dropdown-menu {
            a{
                &:hover{
                    background-color: rgba(153, 9, 203, 0.1) !important;
                    color: #7a07a2 !important;
                }
            }
        }
    }
}


.profile, .nav-menu{
    .dropdown-menu.show{
        transform: translate3d(0px, 55px, 0px) !important;
    }
}

.instruction-steps{
    font-size: 15px;
}

.prevent-edit {
    position: relative;
    user-select: none;
    pointer-events: none;
    min-height: 500px;
    overflow: hidden;
    
    &::after{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        z-index: 999;
        animation: 1s fadeOut 0.5s ease forwards;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.fr-qi-helper a.fr-btn.fr-floating-btn {
    padding: 0px 10px 10px 10px !important;
}

.mobile-nav{
    padding: 20px;
    width: 300px;
    position: fixed;
    left: 0;
    top: 0;
    background: $white;
    z-index: 9999;
    height: 100vh;
    transform: translateX(-300px);
    @include transition;
    nav{
        li{
            padding: 10px 0;
            display: block;
            a{ 
                color: $text-dark-theme;
                svg{
                    width: 18px;
                    height: 18px;
                    margin-right: 10px;
                }
            }
        }  
    }
    a.logo{
        img{
            height: 25px;
        }
    }
}
.overlay{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    background-color: rgba($color: $text-dark-theme, $alpha: 0.5);
}
.open-navbar{
    .mobile-nav{
        transform: translateX(0);
        @include transition;
    }
    .overlay{
        display: block;
    }
}


.campaign-review{

    .campaign-profile {
        display: flex;
        gap: 11px;
        align-content: center;
        align-items: center;

        .from-name {
            font-size: 14px;
            margin-bottom: 3px;
            font-weight: 600;

            small {
                font-weight: 400;
                margin-left: 5px;
            }
        }

        .to {
            display: flex;
            gap: 10px;

            .group-name {
                background: #ebebef;
                padding: 3px 10px;
                border-radius: 3px;
                font-size: 14px;
            }
        }

    }


    .subject {
        align-items: center;
        display: flex;
        font-size: 22px;
        gap: 10px;
        line-height: normal;
        justify-content: flex-start;
        margin-left: 10px;

        .send-time {
            background-color: #fbbc04;
            -webkit-border-radius: 200px;
            border-radius: 200px;
            color: #202124;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0.3px;
            line-height: 16px;
            margin: 0 8px 0 0;
            padding: 5px 10px;
            white-space: nowrap;
        }
    }

    .reply-to {
        font-size: 13px;
        margin-top: 3px;
        margin-left: 10px;
    }
    
}
.nav-close-btn{
    svg{
        stroke: $text-dark-theme;
    }
}

.stroke-primary{
    stroke: $primary-theme;
}
.stroke-secondary{
    stroke: $secondary-theme;
}

// hide show password
.show-pass{
    position: relative;
    .is-invalid{
        + .toggle-password{
            bottom: 37px; 
        }
    }
    .toggle-password{
        position: absolute;
        bottom: 11px; 
        right: 15px;
        margin: auto; 
        height: fit-content; 
        &.active{
            svg{
                path, line, circle{
                    stroke: $primary-theme;
                }
            } 
            .feather-eye-off{
                display: none;
            }
            .feather-eye{
                display: block;
                top: -1px;
                position: relative;
            }
        }
        svg{
            height: 20px;
            path, line, circle{
                stroke: $text-dark-theme;
            }
        }
        .feather-eye{
            display: none;
        }
    }
} 


ul.statistics-info {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 25px;
    font-size: 18px;
    font-weight: 500;
    color: #8608b2;
}

@import "responsive";