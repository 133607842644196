.select2-selection{
    padding: 0 !important; 
}
.select2-container--default .select2-search--inline .select2-search__field{
    height: 50px;
    line-height: 50px;
    padding: 0 10px;
    &::placeholder{
        color: rgba($color: $text-dark-theme, $alpha: 0.3);
    }
}
.select2-search__field{
    margin: 0 !important;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice{
    margin: 2px 0 0 7px !important;
    padding: 5px 5px 5px 20px;
    border: none;
}
.select2-container--default .select2-selection--multiple,
.select2-container--default.select2-container--focus .select2-selection--multiple{
    border: thin solid rgba($color: $text-dark-theme, $alpha: 0.15);
    height: 50px;
    display: flex;
    align-items: center;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove{
    border: none;
    padding: 4px 6px;
}

.radio-label{
    .check{
        width: 20px;
        height: 20px;
        border-radius: 15px;
        border: thin solid rgba($color: $text-dark-theme, $alpha: 0.3);
        
    }
}

.bg-light2{
    background-color: rgba($color: $text-dark-theme, $alpha: 0.05);
}

.btn-light2{
    background-color: rgba($color: $text-dark-theme, $alpha: 0.05); 
    &:hover{
        background-color:  transparent;
        border: thin solid rgba($color: $secondary-theme, $alpha: 0.2) !important;
        color: rgba($color: $secondary-theme, $alpha: 1);
    }
}

.text-primary{
    color: $primary-theme !important;
}

.text-green{
    color: $green;
}

.text-secondary{
    color: $secondary-theme !important;
}

.btn{
    font-size: 14px !important;
    padding: 8px 20px !important;
}

.form-control{
    font-size: 14px !important;
    padding: 10px 15px !important;
    border-color: rgba($color: $text-dark-theme, $alpha: 0.15) !important;
    &::placeholder{
        color: rgba($color: $text-dark-theme, $alpha: 0.3) !important;
    }
}

.btn-primary2{
    background-color: $primary-theme !important;
    color: $white !important;
    &:hover{
        background-color: $secondary-theme !important;
        color: $white !important;
    }
}

.btn-outline-grey{
    border-color: rgba($color: $text-dark-theme, $alpha: 0.15) !important;
    color: $text-dark-theme !important; 
    path{
        @include transition;
    }
    &:hover{
        background-color: $secondary-theme !important;
        color: $white !important;
        path{
            stroke: $white;
            @include transition;
        }
    }
}

.btn-outline-primary2{
    border-color: $secondary-theme !important;
    color: $secondary-theme !important; 
    &:hover{
        background-color: $secondary-theme !important;
        color: $white !important;
    }
}

.form-check-input{
    &:checked {
        background-color: $primary-theme !important;
        border-color: $primary-theme !important;
    }
    &:focus {
        border-color: rgba($color: $primary-theme, $alpha: 0.3) !important; 
        box-shadow: 0 0 0 3px rgba($color: $primary-theme, $alpha: 0.1) !important;
    }
}

.form-control:focus {  
    border-color: rgba($color: $primary-theme, $alpha: 0.3) !important; 
    box-shadow: 0 0 0 3px rgba($color: $primary-theme, $alpha: 0.1) !important;
}

ul.dropdown-menu{
    border: none;
    li{
        a{
            padding: 5px 15px;
            font-size: 15px;
            &:hover{
                background-color: rgba($color: $primary-theme, $alpha: 0.1) !important;
            }
            &:active{
                background-color: $primary-theme !important;
            }
        }
    }
}

.theme-btn{
    background-color: $secondary-theme;
    border-radius: 50px;
    height: 50px;
    padding: 0 30px;
    color: $text-dark-theme;
    display: inline-block;
    line-height: 50px;
    font-weight: 600;
}

.card{
    border-color: rgba($color: $text-dark-theme, $alpha: 0.1) !important;
}


