// max 1400px
@include media-breakpoint-down(xxl) {

}

// max 1200px
@include media-breakpoint-down(xl) {
    .content{ 
        .card-body{
            .template-listing{
                li{
                    width: 25%;
                }
            }
        }
    } 
}

// max 992px
@include media-breakpoint-down(lg) {
    .container-md, .container-sm, .container {
        max-width: 90%;
    }
    header{
        nav{
            display: none;
        }
        .nav-open-btn{
            display: block;
        }
    }
    .lg-data-list{
        .list{
            .list-items{
                position: relative;
                > li{
                    position: relative;
                    padding: 20px 15px;
                    .details{
                        .statistics{
                            flex-wrap: wrap;
                            li{
                                font-size: 14px;
                                width: 50%;
                                padding: 2px 0;
                                border: 0; 
                            }
                        }
                    }
                    .status{
                        position: absolute;
                        right: 20px;
                        top: 65px;
                    }
                    .action{
                        position: absolute;
                        top: 20px;
                        right: 20px;
                    }
                    .icon{
                        min-width: auto;
                        width: fit-content;
                    }
                }
            }
        } 
    }
    .content{
        .card-head{
            .breadcrumb{
                .breadcrumb-item{
                    font-size: 13px;
                    padding-left: 10px;
                    &::before{
                        padding-right: 10px;
                    }
                }
            }
        }
        .card-body{
            .template-listing{
                li{
                    width: 33%;
                }
            }
        }
    } 
    .lg-data-list{
        .list{
            .list-items{
                > li{
                    .icon{
                        > svg {
                            height: 25px;
                            min-width: 40px;
                        }
                    }
                }
            }
        }
    }
}

// max 768px
@include media-breakpoint-down(md) {
    .container-md, .container-sm, .container {
        max-width: 95%;
    }
    .split-screen{ 
        .left{ 
            padding: 0;
            width: 20%;
            .icon{
                img{
                    height: auto;
                    width: 100%;
                }
            }
        }
        .right{ 
            width: 80%;
            padding: 0 50px;
        }
    }
    .lg-data-list{
        .filter{
            display: none;
        }
    }
    .data-list{
        .sort-by{
            display: none !important;
        }
    }
    .content{
        .card-body{
            .search{
                input{
                    min-width: auto;
                    margin-right: 10px;
                }
            }
        }
    }
    .lg-data-head{
        .date-wraper {
            display: block;
            #date-display{
                padding-bottom: 0 !important;
                .date-pick{
                    margin-right: 0 !important;
                    a{
                        padding: 0 !important;
                        svg{
                            height: 20px;
                            width: 20px;
                        }
                    }
                    #month-year{
                        white-space: nowrap;
                        font-size: 14px;
                    }
                }
            }
        }
    }
    header{
        .logo{
            img {
                height: 30px;
                width: 40px;
                object-fit: cover;
                object-position: left;
            }
        }
    }
    .content{
        .card-head{ 
            display: block !important;
            .breadcrumb{
                display: none;
            }
            h5{
                font-size: 18px !important;
            }
        }
        .card-body{
            .template-listing{
                li{
                    width: 33%;
                    min-width: initial;
                    max-width: initial;
                    margin-bottom: 15px;
                }
            }
        }
    }
    label{
        font-size: 14px;
    } 
    .campaign-review{
        .subject{
            display: block;
            .send-time{
                width: fit-content;
                margin-top: 10px;
            }
        }
    }
}


// max 576px
@include media-breakpoint-down(sm) { 
    .content{
        .card-head{  
            h5{
                font-size: 16px !important;
                font-weight: 600;
            }
        }
        .card-body{
            .search{
                input{
                    padding-left: 30px !important;
        
                }
            }
        }
    }
    .swal2-container.swal2-center.swal2-backdrop-show{
        padding: 25px;
    }
    .split-screen{
        display: block;
        .left{
            display: none;
        }
        .right{
            width: 100%;
            padding: 30px;
            .logo{
                left: 30px;
            }
        }
    }
    .lg-data-list{
        .list{
            .list-items{
                > li{
                    .status{
                        font-weight: 600;
                        font-size: 13px;
                        .draft{
                            gap: 5px !important;
                            svg{
                                height: 15px;
                            }
                        }
                    }
                    .icon{
                        padding-right: 10px;
                    }
                    .details{
                        width: 80% !important;
                        h6{
                            padding-right: 30px;
                        }
                        p{
                            margin: 5px 0 !important;
                            span{
                                display: block;
                            }
                        }
                        .statistics{
                            margin-top: 5px;
                            li{
                                font-size: 12px;
                                padding: 0;
                            }
                        }
                    }
                    .action{
                        a.edit{
                            display: flex;
                            align-items: center;
                            padding: 0 5px;
                            svg{
                                display: block;
                                height: 18px;
                            }
                            span{
                                display: none;
                            }
                        }
                    }
                    .icon{
                        display: none;
                    }
                }
            }
        }
        // &.three-column .details {
        //     width: 80% !important;
        // }
    }
    .content{
        .card-body{
            .template-listing{
                li{
                    width: 50%;
                    min-width: initial;
                    max-width: initial;
                    margin-bottom: 15px;
                }
            }
        }
    }
    .mobile-none{
        display: none;
    }
    #date-display{
        h6{
            white-space: nowrap;
            padding: 0 !important;
        }
        a{
            padding: 0px !important;
        }
    } 
    .campaign-review{
        .campaign-profile{
            .from-name{
                small{
                    display: block;
                    margin: 0;
                }
            }
            .to{
                gap: 5px;
                flex-wrap: wrap;            
                .group-name{
                    font-size: 12px;
                    padding: 2px 8px;
                    white-space: nowrap;
                }
            }
        }
    }
    .content{
        .card-body{
            .template-listing{
                li{
                    .preview{
                        a{
                            width: 100px;
                        }
                    }
                }
            }
        }
    }
    .data-list{
        .search{
            width: 100%;
        }
    }
    .send-test-mail{
        justify-content: start !important;
        margin-top: 15px;
    }
}

@media only screen and (max-width: 450px) {
    .campaign-details > .d-flex{
        display: block !important;
        margin-bottom: 10px;
        label{
            width: 100%;
            margin: 0;
        }
    }
} 